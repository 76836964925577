import { useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Spacer,
  Text,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import ChannelFAB from "components/button/channel-fab-button";
import InviteEmailListInput from "components/invite-email-list-input";
import Layout from "components/layout";
import {
  useCreateOrganization,
  useFetchOrganizations,
} from "lib/organization/api";
import { MAX_ORGANIZATIONS_NAME_LENGTH } from "lib/organization/const";
import { useOrganizationContext } from "lib/organization/organization-context";

type FormSchema = {
  organizationName: string;
  emailList: { value: string }[];
};

const IndexPage = () => {
  const router = useRouter();
  const { currentOrganization } = useOrganizationContext();
  const { organizations, isLoading } = useFetchOrganizations();

  if (!isLoading && organizations?.length > 0) {
    const organization = currentOrganization || organizations[0];
    router.push(`/organizations/${organization.id}`);
  }

  const [currentStep, setCurrentStep] = useState(1);
  const [isProcessing, setIsProcessing] = useState(false);
  const { t } = useTranslation("organizations");

  const { mutate, invitationResults } = useCreateOrganization();
  const toast = useToast();

  const schema = yup.object().shape({
    organizationName: yup
      .string()
      .required(t("organizations:organizationNameRequiredError"))
      .max(
        MAX_ORGANIZATIONS_NAME_LENGTH,
        t("organizations:organizationNameMaxLengthError")
      ),
  });

  const useFormReturn = useForm<FormSchema>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      organizationName: "",
      emailList: [],
    },
  });

  const {
    watch,
    handleSubmit,
    trigger,
    register,
    formState: { errors, isValid },
  } = useFormReturn;

  const titleNameLength = watch().organizationName.length || 0;

  const onSubmit = async ({ organizationName, emailList }: FormSchema) => {
    try {
      await mutate({
        name: organizationName,
        emailAddresses: emailList.map((v) => v.value),
      });
      toast({
        title: t("successToCreateOrganization"),
        status: "success",
        position: "top",
        isClosable: true,
      });

      invitationResults
        .filter((i) => i.inviteStatus === "error")
        .map((i) => {
          toast({
            title: t("failtToInvite", { email: i.emailAddress }),
            status: "error",
            position: "top",
            isClosable: true,
          });
        });
    } catch (e) {
      toast({
        title: t("failToCreateOrganization"),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  const StepList = [
    {
      render: () => (
        <FormControl isInvalid={!!errors.organizationName}>
          <FormLabel mb={4}>
            <Flex className="justify">
              {t("organizationName")}
              <Text as="span" color="red">
                *
              </Text>
              <Spacer />
              <Text
                color={
                  titleNameLength > MAX_ORGANIZATIONS_NAME_LENGTH
                    ? "red"
                    : "black"
                }
              >
                {titleNameLength} / {MAX_ORGANIZATIONS_NAME_LENGTH}文字
              </Text>
            </Flex>
          </FormLabel>
          <Input
            placeholder={t("organizationNamePlacecholder")}
            {...register("organizationName" as const)}
          />
          <FormErrorMessage>
            {errors.organizationName?.message}
          </FormErrorMessage>
        </FormControl>
      ),
      onNext: () => {
        trigger("organizationName");
      },
    },
    {
      render: () => (
        <InviteEmailListInput
          /*
            型 'UseFormReturn<FormSchema, any>' を型 'UseFormReturn<BaseFieldValue, any>'
            に割り当てられずエラーになるためキャストしている。
            以下の issue の議論を参考に、部分的なフォームを作成する方法を実践したが、フォームの型に
            Arrayが含まれる場合はうまくいかない模様。
            https://github.com/react-hook-form/react-hook-form/issues/6726#issuecomment-939467832
          */
          form={
            useFormReturn as unknown as UseFormReturn<
              Pick<FormSchema, "emailList">
            >
          }
          label={t("letsInviteMember")}
        />
      ),
      nextButtonType: "submit" as const,
      onNext: async () => {
        trigger("emailList");
        await handleSubmit(onSubmit)();
      },
    },
    {
      render: () => (
        <Text>
          {t("completeCreateRoomMessage1")}
          <br />
          {t("completeCreateRoomMessage2")}
        </Text>
      ),
      onNext: () => {
        router.push("/studio");
      },
      disableBack: true,
    },
  ];

  return (
    <Layout title={t("welcomeToTsucom")} showSidebar={true}>
      <Box
        w="lg"
        h="md"
        py={8}
        px={12}
        mx="auto"
        my={12}
        position="relative"
        boxShadow="lg"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Heading as="h2" size="md" mb={12}>
          {t("createOrganizationMessage")}
        </Heading>
        <Text fontSize="md" fontWeight="bold" color="" mb={8}>
          {t("createOrganizationStep", {
            currentStep: currentStep,
            stepLength: StepList.length,
          })}
        </Text>

        {StepList[currentStep - 1].render()}

        <Flex w="80%" position="absolute" bottom={12}>
          <Button
            type="button"
            colorScheme="aisekiBlue"
            color="aisekiWhite.500"
            hidden={StepList[currentStep - 1].disableBack || currentStep <= 1}
            onClick={() => {
              setCurrentStep((prev) => prev - 1);
            }}
          >
            {t("back")}
          </Button>
          <Spacer />
          <Button
            colorScheme="aisekiBlue"
            color="aisekiWhite.500"
            isLoading={isProcessing}
            disabled={!isValid}
            onClick={async () => {
              setIsProcessing(true);
              await StepList[currentStep - 1].onNext();
              if (currentStep < StepList.length) {
                setCurrentStep((prev) => prev + 1);
              }
              setIsProcessing(false);
            }}
          >
            {currentStep === StepList.length ? t("complete") : t("next")}
          </Button>
        </Flex>
      </Box>
      <ChannelFAB />
    </Layout>
  );
};

export default IndexPage;
